import { GET_SETTING } from '../actions/types'
//

const initialState = {
  settingData: null,
  loading: true,
}

export default function setting(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_SETTING:
      return {
        ...state,
        settingData: payload,
        loading: false,
      }

    default:
      return state
  }
}
