export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const DELETE_ALL_ALERT = "DELETE_ALL_ALERT";
export const ADMIN_LOADED = "ADMIN_LOADED";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_USERS = "GET_USERS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_ALL_ACTIVE_USERS = "GET_ALL_ACTIVE_USERS";
export const GET_USER_REVIEW = "GET_USER_REVIEW";

//
export const GET_ALL_CMS = "GET_ALL_CMS";

//
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_ALL_SUB_CATEGORY = "GET_ALL_SUB_CATEGORY";
export const SET_LOADING = "SET_LOADING";

export const GET_ALL_BUSINESS = "GET_ALL_BUSINESS";
export const GET_ALL_GALLERY = "GET_ALL_GALLERY";
export const GET_SETTING = "GET_SETTING";
export const GET_CONTACT = "GET_CONTACT";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_SELECT_CATEGORY = "GET_SELECT_CATEGORY";
export const GET_PRODUCT_POSTED = "GET_PRODUCT_POSTED";
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";

//
export const GET_LABELS = "GET_LABELS";

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_MAKE_lIST = "GET_MAKE_lIST";
export const GET_MODEL_lIST = "GET_MODEL_lIST";

export const GET_EMAIL_ACTIVE_USER_LIST = "GET_EMAIL_ACTIVE_USER_LIST";
export const GET_PUSH_ACTIVE_USER_LIST = "GET_PUSH_ACTIVE_USER_LIST";

export const GET_EMAIL_NOTIFICATION_HISTORY = "GET_EMAIL_NOTIFICATION_HISTORY";
export const GET_PUSH_NOTIFICATION_HISTORY = "GET_PUSH_NOTIFICATION_HISTORY";