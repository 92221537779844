import { GET_LANGUAGES } from "../actions/types";

const initialState = {
  languages: [],
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LANGUAGES:
      return { ...state, loading: false, languages: payload, };

    default:
      return state;
  }
}
