import { GET_LABELS } from "../actions/types";

const initialState = {
  labels: [],
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LABELS:
      return { ...state, loading: false, labels: payload };
    default:
      return state;
  }
}
