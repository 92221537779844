import {
  GET_USERS,
  GET_ALL_ACTIVE_USERS,
  GET_USER_DETAILS,
  GET_USER_REVIEW,
  GET_EMAIL_ACTIVE_USER_LIST,
  GET_PUSH_ACTIVE_USER_LIST,
} from "../actions/types";
const initialState = {
  userData: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  userReview: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
  user: {},
  userAllData: [],
  allEmailActiveUser: [],
  allPushActiveUser: [],
};

export default function user(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USERS:
      return { ...state, loading: false, userData: payload };
    case GET_ALL_ACTIVE_USERS:
      return { ...state, loading: false, userAllData: payload };
    case GET_USER_DETAILS:
      return { ...state, loading: false, user: payload };
    case GET_USER_REVIEW:
      return { ...state, loading: false, userReview: payload };
    case GET_EMAIL_ACTIVE_USER_LIST:
      return { ...state, loading: false, allEmailActiveUser: payload };
    case GET_PUSH_ACTIVE_USER_LIST:
      return { ...state, loading: false, allPushActiveUser: payload };
    default:
      return state;
  }
}
