import {
  GET_ALL_CATEGORY,
  GET_ALL_SUB_CATEGORY,
  GET_SELECT_CATEGORY,
  GET_PRODUCT_POSTED,
  GET_PRODUCT_DETAIL,
  GET_ALL_PRODUCTS
} from '../actions/types'

const initialState = {
  categoryList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  subCategoryList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  getProducts: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  products: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  prodetails: {},


  loading: true,
}

const category = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_CATEGORY:
      return { ...state, loading: false, categoryList: payload, }
    case GET_ALL_SUB_CATEGORY:
      return { ...state, loading: false, subCategoryList: payload, }
    case GET_SELECT_CATEGORY:
      return { ...state, loading: false, categorySelect: payload, }
    case GET_PRODUCT_POSTED:
      return { ...state, loading: false, products: payload, }
    case GET_PRODUCT_DETAIL:
      return { ...state, loading: false, prodetails: payload, }
    case GET_ALL_PRODUCTS:
      return { ...state, loading: false, getProducts: payload, }
    case 'SET_LOADING':
      return { ...state, loading: true, }

    default:
      return state
  }
}
export default category
